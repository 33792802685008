import Head from 'next/head'

import { Container } from '~/components/container'
import { Footer } from '~/components/footer'
import { Header } from '~/components/header'
import { Logo } from '~/components/logo'

export default function Custom404() {
	return (
		<>
			<Head>
				<title>Page Not Found - Message Decoder</title>
			</Head>
			<Header />
			<Container className="min-h-screen-header flex items-center justify-center py-16 lg:py-32">
				<section className="bg-white dark:bg-gray-900">
					<div className="mx-auto max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16">
						<div className="mx-auto max-w-screen-sm text-center">
							<h1 className="text-primary-600 dark:text-primary-500 mb-4 text-7xl font-extrabold tracking-tight lg:text-9xl">
								404
							</h1>
							<p className="mb-4 text-3xl font-bold tracking-tight text-gray-900 dark:text-white md:text-4xl">
								Something's missing.
							</p>
							<p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
								Sorry, we can't find that page. You'll find lots to explore on the home
								page.{' '}
							</p>
							<a
								href="/"
								className="inline-block rounded-lg px-2 py-1 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900 dark:text-slate-400 dark:hover:bg-slate-800 dark:hover:text-slate-50"
							>
								Back to Homepage
							</a>
						</div>
					</div>
				</section>
			</Container>
			<Footer />
		</>
	)
}
